import { template as template_c3feb75bcdc2495cbe30298acb1edc82 } from "@ember/template-compiler";
const WelcomeHeader = template_c3feb75bcdc2495cbe30298acb1edc82(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;

import { template as template_9c5aca6d3b5f4d4c9fcc333d6a6ecd18 } from "@ember/template-compiler";
const FKControlMenuContainer = template_9c5aca6d3b5f4d4c9fcc333d6a6ecd18(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
